import React, { FormEvent, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import logo from "../../../assets/img/conexaonr.svg";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import api from "../../components/api";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { iAuthResponse, iGetDatas } from "./iAuthResponse";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function handleSubmit(e: FormEvent<HTMLDivElement>) {
    e.preventDefault();

    const MySwal = withReactContent(Swal);

    try {
      const Authentication = await api.get<iAuthResponse>("/auth", {
        auth: {
          username,
          password,
        },
      });

      const GetDatas = await api.get<iGetDatas>("/auth/me", {
        headers: {
          Authorization: `Bearer ${Authentication.data.token}`,
        },
      });

      if (GetDatas.data.userData.type !== "admin") {
        MySwal.fire({
          title: "Usuário invalido.",
          icon: "error",
        });
      } else {
        await MySwal.fire({
          title: "Login realizado sucesso!",
          icon: "success",
        });

        localStorage.setItem("jwt", Authentication.data.token);
        window.location.href = "/admin/dashboard";
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Usuário ou senha estão incorretos.",
      });
    }
  }

  return (
    <Box
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="100vw"
      h="100vh"
      bgColor="#F4F6FA"
    >
      <FormControl
        as="form"
        onSubmit={handleSubmit}
        d="flex"
        flexDirection="column"
        alignItems="center"
        w="100%"
        maxW="630px"
        h="100%"
        maxH="468px"
        bg="#fff"
        p={8}
        rounded="8px"
        shadow="0px 0px 10px rgba(0, 0, 0, 0.05);"
      >
        <Image src={logo} alt="conexãonr" w="226px" mb={8} />
        <Box
          w="100%"
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box w="80%" mb={8}>
            <Text fontWeight="light" sx={{ fontFamily: "Poppins" }}>
              Usuário
            </Text>
            <Input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              borderColor="#4ECB71"
              border="2px"
              _hover={{ border: "2px solid #4ECB71" }}
              _focus={{ border: "2px solid #4ECB71" }}
            />
          </Box>
          <Box w="80%" mb={16}>
            <Text fontWeight="light" sx={{ fontFamily: "Poppins" }}>
              Senha
            </Text>
            <Input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              borderColor="#4ECB71"
              border="2px"
              _hover={{ border: "2px solid #4ECB71" }}
              _focus={{ border: "2px solid #4ECB71" }}
            />
          </Box>
          <Box w="80%">
            <Button
              type="submit"
              color="white"
              bg="#4ECB71"
              w="100%"
              _hover={{ backgroundColor: "#4ECB71" }}
              _active={{ backgroundColor: "#4ECB71" }}
            >
              Fazer login
            </Button>
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

export default Login;
