import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface iItemHeader {
  title: string;
  filename: string;
}

export const ItemHeader: React.FC<iItemHeader> = ({ title, filename }) => {
  return (
    <>
      <Box
        d="flex"
        w="100%"
        flexDir={["column", "row"]}
        alignItems={["center", "start"]}
        mb={[2, 0]}
      >
        <Box
          minW="352px"
          maxW="352px"
          minH="184px"
          maxH="184px"
          bg="#C4C4C4"
          sx={{
            backgroundImage: `${process.env.REACT_APP_API_IMG}/${filename}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          shadow="10px 10px 40px rgba(7, 40, 43, 0.14);"
          rounded="14px"
        ></Box>
        <Box
          w="flex"
          h="100%"
          ml={[0, 3]}
          minW={["50%", "30%"]}
          maxW={["90%", "40%"]}
        >
          <Text fontSize={["5xl", "6xl"]} fontWeight="black" title="">
            {title}
          </Text>
        </Box>
      </Box>
    </>
  );
};
