import React, { useEffect, useState } from "react";
import { Button, Text, Image } from "@chakra-ui/react";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { SideBar } from "../Dashboard/SideBar";

import HomeIcon from "../../../assets/img/HomeIcon.svg";
import BlogIcon from "../../../assets/img/BlogIcon.svg";
import LogoutIcon from "../../../assets/img/LogoutIcon.svg";
import api from "../../components/api";
import HTMLReactParser from "html-react-parser";

import EditIcon from "../../../assets/img/EditIcon.svg";
import TrashIcon from "../../../assets/img/TrashIcon.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

// import { Container } from './styles';

export interface iPosts {
  id: number;
  type: string;
  titulo: string;
  filename: string;
  code: string;
  created_at: Date;
  updated_at: Date;
}

const AdminPosts: React.FC = () => {
  const [posts, setPosts] = useState<iPosts[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<iPosts[]>("/posts");
        setPosts(response.data.reverse());
      } catch (err) {}
    })();
  }, [setPosts]);

  async function ExcludePost(id: number | string) {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: "warning",
      title: "Você tem certeza que deseja deletar esta postagem?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await api.delete("/posts", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            id: `${id}`,
          },
        });
        window.location.href = "/admin/dashboard";
      }
    });
  }

  return (
    <>
      <Box d="flex" flexDirection={["column", "row"]} flex="1">
        <SideBar
          itens={[
            {
              name: "Dashboard",
              to: "/admin/dashboard",
              icon: HomeIcon,
              active: false,
            },
            {
              name: "Blog post",
              to: "/admin/posts",
              icon: BlogIcon,
              active: true,
            },
            {
              name: "Nova postagem",
              to: "/admin/new/post",
              icon: BlogIcon,
              active: false,
            },
            {
              name: "Log Out",
              to: "",
              icon: LogoutIcon,
              active: false,
              handleClick: () => {
                localStorage.removeItem("jwt");
                window.location.href = "/admin";
              },
            },
          ]}
        />
        <Box
          d="flex"
          justifyContent="space-between"
          flexDirection="column"
          overflowX="hidden"
          w="100%"
          minH="100vh"
          h="max-content"
          bg="#F4F6FA"
        >
          <Box
            d="grid"
            w="100%"
            gridTemplateColumns={["1fr", "repeat(2, 1fr)"]}
            mt={[0, 8]}
            ml={[0, 5]}
            py={[8, 0]}
            px={[4, 0]}
            gridGap={["1px", "20px"]}
          >
            <Stack spacing={8}>
              {posts.map((item) => (
                <>
                  <Box
                    d="flex"
                    w="100%"
                    flexDir={["column", "row"]}
                    alignItems="center"
                    mb={[2, 0]}
                    bg="#fff"
                    p={4}
                    rounded="8px"
                  >
                    <Box
                      minW={["300px", "352px"]}
                      maxW={["300px", "352px"]}
                      minH="184px"
                      maxH="184px"
                      bg="#C4C4C4"
                      sx={{
                        backgroundImage: `${process.env.REACT_APP_API_IMG}/${item.filename}`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      shadow="10px 10px 40px rgba(7, 40, 43, 0.14);"
                      rounded="14px"
                    ></Box>
                    <Flex
                      h="100%"
                      ml={[0, 3]}
                      minW={["90%", "25%"]}
                      maxW={["90%", "50%"]}
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Text
                          fontSize="2xl"
                          fontWeight="black"
                          noOfLines={[1, 2]}
                          title={item.titulo}
                        >
                          {item.titulo}
                        </Text>
                        <Text fontSize="18px" noOfLines={2} color="gray.500">
                          {HTMLReactParser(item.code.split("</p>")[0])}
                        </Text>
                      </Box>
                      <Flex>
                        <Button
                          as={Link}
                          onClick={() => {
                            localStorage.setItem("postID", `${item.id}`);
                          }}
                          to={`/admin/edit`}
                          bg="transparent"
                          _focus={{}}
                          _hover={{}}
                          _active={{}}
                          w="24px"
                          h="24px"
                          p={2}
                        >
                          <Image src={EditIcon} />
                        </Button>
                        <Button
                          bg="transparent"
                          _focus={{}}
                          _hover={{}}
                          _active={{}}
                          w="24px"
                          h="24px"
                          p={2}
                          onClick={() => {
                            ExcludePost(item.id);
                          }}
                        >
                          <Image src={TrashIcon} w="24px" h="24px" />
                        </Button>
                      </Flex>
                    </Flex>
                  </Box>
                </>
              ))}
            </Stack>
            <Box w="100%">{/* <SpeedPost /> */}</Box>
          </Box>

          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            w="100%"
            bg="#fff"
            p={5}
            mt={8}
          >
            <Text
              textAlign="center"
              color="#333"
              fontWeight="semibold"
              fontSize="1xl"
            >
              Copyright © 2021 Todos direitos reservados | Desenvolvido por Grow
              Startup
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminPosts;
