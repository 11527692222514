import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./Home";
import theme from "./theme";
import Post from "./Post";
// import Public from "./Public";
import Login from "./Admin/Login";
import Dashboard from "./Admin/Dashboard";
import AdminPosts from "./Admin/Posts";
import Edit from "./Admin/Dashboard/Edit";
import NewPost from "./Admin/Posts/new";

const Routes: React.FC = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/post/:id" component={Post} exact />
            <Route path="/admin" component={Login} exact />
            <Route path="/admin/edit" component={Edit} exact />
            <Route path="/admin/dashboard" component={Dashboard} exact />
            <Route path="/admin/new/post" component={NewPost} exact />
            <Route path="/admin/posts" component={AdminPosts} exact />
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
};

export default Routes;
