import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/image";
import { Box, Link as LinkStyle } from "@chakra-ui/layout";
import logo from "../../../assets/img/conexaonr.svg";
import MenuIcon from "../../../assets/img/MenuIcon.svg";
import MenuClose from "../../../assets/img/MenuClose.svg";
import { Button, SlideFade, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import HomeIcon from "../../../assets/img/HomeIcon.svg";
import BlogIcon from "../../../assets/img/BlogIcon.svg";
import LogoutIcon from "../../../assets/img/LogoutIcon.svg";

interface iMenuItem {
  name: string;
  icon: string;
  active: boolean;
  to: string;
  handleClick?: Function;
}

interface iMenuRequest {
  itens?: iMenuItem[];
}

export const SideBar: React.FC<iMenuRequest> = ({ itens }) => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  const itensDefault: iMenuItem[] = [
    {
      name: "Dashboard",
      to: "/admin/dashboard",
      icon: HomeIcon,
      active: true,
    },
    {
      name: "Blog post",
      to: "/admin/posts",
      icon: BlogIcon,
      active: false,
    },
    {
      name: "Nova postagem",
      to: "/admin/new/post",
      icon: BlogIcon,
      active: false,
    },
    {
      name: "Log Out",
      to: "/admin/dashboard",
      icon: LogoutIcon,
      active: false,
      handleClick: () => {
        localStorage.removeItem("jwt");
        window.location.href = "/admin";
      },
    },
  ];

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      if (!open) {
        setScrolled(true);
      }
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  return (
    <>
      <Box
        zIndex="999"
        d={["", "none"]}
        width="100%"
        top="0px"
        left="0px"
        position={`${scrolled ? "fixed" : "relative"}`}
        flexDirection="column"
        borderRight="2px solid rgba(204, 204, 204, 0.25);"
        bg="#fff"
        pt={7}
        px={7}
        pb={6}
        borderBottom="2px solid #EEEEEE;"
      >
        <Box d="flex" justifyContent="space-between">
          <Image src={logo} alt="Conexão NR Blog" w="198px" h="31.50px" />
          <Button
            w="auto"
            bg="transparent"
            _hover={{}}
            _focus={{}}
            _active={{}}
            onClick={() => {
              open ? setOpen(false) : setOpen(true);
            }}
          >
            <Image src={open ? MenuClose : MenuIcon} />
          </Button>
        </Box>
        {open && (
          <Box as={SlideFade} zIndex="999" delay={0.1} in={open}>
            {itens
              ? itens.map((item) => (
                  <LinkStyle
                    mt={6}
                    key={item.name}
                    d="flex"
                    alignItems="center"
                    as={item.handleClick ? LinkStyle : Link}
                    to={item.handleClick ? "" : item.to}
                    onClick={() => {
                      if (item.handleClick) {
                        item.handleClick();
                      }
                    }}
                    userSelect="none"
                    color="#4ECB71"
                    outline="none"
                    textDecoration="none"
                    _hover={{ textDecoration: "none" }}
                    _focus={{ border: "none" }}
                    fontSize="20px"
                    bg={`${item.active ? "rgba(78, 203, 113, 0.3)" : ""}`}
                    px={`${item.active ? "14px" : "14px"}`}
                    py={`${item.active ? "8px" : ""}`}
                    borderRadius="8px"
                  >
                    <Image src={item.icon} mr={2} />
                    {item.name}
                  </LinkStyle>
                ))
              : itensDefault.map((item) => (
                  <LinkStyle
                    mt={6}
                    key={item.name}
                    d="flex"
                    alignItems="center"
                    as={item.handleClick ? LinkStyle : Link}
                    to={item.handleClick ? "" : item.to}
                    onClick={() => {
                      if (item.handleClick) {
                        item.handleClick();
                      }
                    }}
                    userSelect="none"
                    color="#4ECB71"
                    outline="none"
                    textDecoration="none"
                    _hover={{ textDecoration: "none" }}
                    _focus={{ border: "none" }}
                    fontSize="20px"
                    bg={`${item.active ? "rgba(78, 203, 113, 0.3)" : ""}`}
                    px={`${item.active ? "14px" : "14px"}`}
                    py={`${item.active ? "8px" : ""}`}
                    borderRadius="8px"
                  >
                    <Image src={item.icon} mr={2} />
                    {item.name}
                  </LinkStyle>
                ))}
          </Box>
        )}
      </Box>
      <Box
        d={["none", "flex"]}
        minW="295.50px"
        alignItems="center"
        flexDirection="column"
        borderRight="2px solid rgba(204, 204, 204, 0.25);"
        bg="#fff"
      >
        <Box mt={8}>
          <Image src={logo} alt="Conexão NR Blog" w="198px" h="31.50px" />
          <Stack as={SlideFade} delay={0.2} in={true}>
            {itens
              ? itens.map((item) => (
                  <LinkStyle
                    mt={6}
                    key={item.name}
                    d="flex"
                    alignItems="center"
                    as={item.handleClick ? LinkStyle : Link}
                    to={item.handleClick ? "" : item.to}
                    onClick={() => {
                      if (item.handleClick) {
                        item.handleClick();
                      }
                    }}
                    userSelect="none"
                    color="#4ECB71"
                    outline="none"
                    textDecoration="none"
                    _hover={{ textDecoration: "none" }}
                    _focus={{ border: "none" }}
                    fontSize="20px"
                    bg={`${item.active ? "rgba(78, 203, 113, 0.3)" : ""}`}
                    px={`${item.active ? "14px" : "14px"}`}
                    py={`${item.active ? "8px" : ""}`}
                    borderRadius="8px"
                  >
                    <Image src={item.icon} mr={2} />
                    {item.name}
                  </LinkStyle>
                ))
              : itensDefault.map((item) => (
                  <LinkStyle
                    mt={6}
                    key={item.name}
                    d="flex"
                    alignItems="center"
                    as={item.handleClick ? LinkStyle : Link}
                    to={item.handleClick ? "" : item.to}
                    onClick={() => {
                      if (item.handleClick) {
                        item.handleClick();
                      }
                    }}
                    userSelect="none"
                    color="#4ECB71"
                    outline="none"
                    textDecoration="none"
                    _hover={{ textDecoration: "none" }}
                    _focus={{ border: "none" }}
                    fontSize="20px"
                    bg={`${item.active ? "rgba(78, 203, 113, 0.3)" : ""}`}
                    px={`${item.active ? "14px" : "14px"}`}
                    py={`${item.active ? "8px" : ""}`}
                    borderRadius="8px"
                  >
                    <Image src={item.icon} mr={2} />
                    {item.name}
                  </LinkStyle>
                ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};
