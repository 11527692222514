import React, { useEffect, useState } from "react";
import { SideBar } from "./SideBar";
import { Image } from "@chakra-ui/image";
import { Box, Text, Stack } from "@chakra-ui/layout";
import ImageIcon from "../../../assets/img/image.svg";
import PostIcon from "../../../assets/img/PostIcon.svg";
import EditIcon from "../../../assets/img/EditIcon.svg";

import { SlideFade } from "@chakra-ui/transition";
import { Button } from "@chakra-ui/button";
import api from "../../components/api";
import { Link } from "react-router-dom";

interface iPosts {
  id: number;
  type: string;
  titulo: string;
  filename: string;
  code: string;
  created_at: Date;
  updated_at: Date;
}

const Dashboard: React.FC = () => {
  const [posts, setPosts] = useState<iPosts[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<iPosts[]>("/posts");
        setPosts(response.data.reverse());
      } catch (err) {}
    })();
  }, []);

  return (
    <>
      {/* {modalEdit && (
        <>
          <ModalEdit postId={postEdit} modalProps={setModalEdit} code={code} />
        </>
      )} */}

      <Box d="flex" flexDirection={["column", "row"]} flex="1">
        <SideBar />

        <Box
          d="flex"
          justifyContent="space-between"
          flexDirection="column"
          overflowX="hidden"
          w="100%"
          minH="100vh"
          h="max-content"
          bg="#F4F6FA"
        >
          <Box
            d="grid"
            w="100%"
            gridTemplateColumns={["1fr", "repeat(2, 1fr)"]}
            mt={[0, 8]}
            ml={[0, 5]}
            py={[8, 0]}
            px={[4, 0]}
            gridGap={["1px", "20px"]}
            height={["max-content", "85vh"]}
          >
            <Box h="max-content">
              <Box
                d="grid"
                gridTemplateColumns={["1fr", "1fr", "1fr", "repeat(2, 1fr)"]}
                gridGap="21px"
                h="max-content"
              >
                <Box
                  d="flex"
                  justifyContent="space-between"
                  w="100%"
                  h="120px"
                  bg="#fff"
                  p={8}
                  borderRadius="8px"
                  shadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
                >
                  <Box as={SlideFade} delay={0.1} in={true}>
                    <Text
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 800,
                      }}
                      fontSize="2xl"
                    >
                      {posts.length}
                    </Text>
                    <Text
                      lineHeight="17px"
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                      fontSize="2xl"
                      color="#666"
                    >
                      Postagens
                    </Text>
                  </Box>
                  <Box d="flex" justifyContent="end" alignItems="end">
                    <Image w={12} src={PostIcon} />
                  </Box>
                </Box>

                <Box
                  d="flex"
                  justifyContent="space-between"
                  w="100%"
                  h="120px"
                  bg="#fff"
                  p={8}
                  borderRadius="8px"
                  shadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
                >
                  <Box as={SlideFade} delay={0.1} in={true}>
                    <Text
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 800,
                      }}
                      fontSize="2xl"
                    >
                      {posts.length}
                    </Text>
                    <Text
                      lineHeight="17px"
                      sx={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                      fontSize="2xl"
                      color="#666"
                    >
                      Imagens
                    </Text>
                  </Box>

                  <Box d="flex" justifyContent="end" alignItems="end">
                    <Image w={14} src={ImageIcon} />
                  </Box>
                </Box>
              </Box>

              <Stack spacing={[4, 6]} mt={8}>
                <Text
                  fontWeight="extrabold"
                  fontSize="2xl"
                  fontFamily="Poppins"
                >
                  Últimas postagens
                </Text>
                {posts.map((item, id) => (
                  <>
                    {id <= 5 && (
                      <>
                        <Box
                          h="100%"
                          mt={4}
                          as={SlideFade}
                          delay={0.2}
                          in={true}
                          d="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          w="100%"
                          bg="#fff"
                          shadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
                          borderRadius="8px"
                          p={5}
                        >
                          <Text
                            fontWeight="extrabold"
                            fontSize="1xl"
                            color="#333"
                            fontFamily="Poppins"
                          >
                            {item.titulo}
                          </Text>
                          <Button
                            as={Link}
                            onClick={() => {
                              localStorage.setItem("postID", `${item.id}`);
                            }}
                            to={`/admin/edit`}
                            bg="transparent"
                            _focus={{}}
                            _hover={{}}
                            _active={{}}
                            w="24px"
                            h="24px"
                            p={2}
                          >
                            <Image src={EditIcon} />
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                ))}
              </Stack>
            </Box>
            <Box>{/* <SpeedPost /> */}</Box>
          </Box>

          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            w="100%"
            bg="#fff"
            p={5}
            mt={8}
          >
            <Text
              textAlign="center"
              color="#333"
              fontWeight="semibold"
              fontSize="1xl"
            >
              Copyright © 2021 Todos direitos reservados | Desenvolvido por Grow
              Startup
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
