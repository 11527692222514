import { Box } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/conexaonr2_rgb-pq.png";

export default function Header() {
  return (
    <>
      <Box
        p={2}
        w="100%"
        d={["none", "flex"]}
        as="header"
        bg="#07282B"
        color="white"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxW="1200px"
          w="100%"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="/">
            <img
              src={Logo}
              alt="Conexão NR - Blog"
              width="200px"
              height="43px"
            />
          </Link>
        </Box>
      </Box>

      <Box
        p={2}
        w="100%"
        d={["flex", "none"]}
        as="header"
        bg="#07282B"
        color="white"
        alignItems="center"
        justifyContent="center"
      >
        <Link to="/">
          <img src={Logo} alt="Conexão NR" />
        </Link>
      </Box>
    </>
  );
}
