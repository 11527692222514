import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

import Header from "../components/Header";
import { Emphasis } from "./iEmphasis";
import { Item } from "./item";
import api from "../components/api";
import { iPosts } from "./iPosts";

const Home: React.FC = () => {
  const [posts, setPosts] = useState<iPosts[]>([]);
  const [frist, setFrist] = useState<iPosts>();

  useEffect(() => {
    (async () => {
      const response = await api.get<iPosts[]>("/posts");
      const data = response.data.reverse();
      setFrist(data[0]);
      delete data[0];
      setPosts(data);
    })();
  }, []);


  return (
    <>
      <Header />
      <Box as="main" d="flex" justifyContent="center">
        <Box d={["none", "block"]} maxW="1200px" w="100%" mt={16}>
          <Emphasis
            title={frist?.titulo || ""}
            description={frist?.code || ""}
            filename={frist?.filename || ""}
            link={"/post/" + frist?.id}
          />
          <Box d="grid" gridTemplateColumns="1fr 1fr" gridGap={8} mt={16}>
            {posts.map((item) => (
              <>
                {item.type.toLowerCase() === "blog" && (
                  <>
                    <Item
                      title={item.titulo || ""}
                      description={item?.code || ""}
                      link={`/post/${item.id}`}
                      filename={item?.filename || ""}
                      key={item.id}
                    />
                  </>
                )}
              </>
            ))}
          </Box>
        </Box>
        <Box
          d={["flex", "none"]}
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          w="100%"
          mt={16}
        >
          <Emphasis
            title={frist?.titulo || ""}
            description={frist?.code || ""}
            filename={frist?.filename || ""}
            link={"/post/" + frist?.id}
          />
          {posts.map((item) => (
            <>
              {item.type.toLowerCase() === "blog" && (
                <>
                  <Emphasis
                    title={item.titulo || ""}
                    description={item?.code || ""}
                    link={`/post/${item.id}`}
                    filename={item?.filename || ""}
                    key={item.id}
                  />
                </>
              )}
            </>
          ))}
          {/* <Emphasis
            title="What is Lorem Ipsum?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            consectetur venenatis blandit. Praesent vehicula, libero non pretium"
            link="/post/..."
          />
          <Emphasis
            title="What is Lorem Ipsum?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            consectetur venenatis blandit. Praesent vehicula, libero non pretium"
            link="/post/..."
          />
          <Emphasis
            title="What is Lorem Ipsum?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            consectetur venenatis blandit. Praesent vehicula, libero non pretium"
            link="/post/..."
          />
          <Emphasis
            title="What is Lorem Ipsum?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            consectetur venenatis blandit. Praesent vehicula, libero non pretium"
            link="/post/..."
          />
          <Emphasis
            title="What is Lorem Ipsum?"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
            consectetur venenatis blandit. Praesent vehicula, libero non pretium"
            link="/post/..."
          /> */}
        </Box>
      </Box>
    </>
  );
};

export default Home;
