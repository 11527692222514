import { Box } from "@chakra-ui/react";
import React from "react";
import HomeIcon from "../../../assets/img/HomeIcon.svg";
import BlogIcon from "../../../assets/img/BlogIcon.svg";
import LogoutIcon from "../../../assets/img/LogoutIcon.svg";
import { SideBar } from "../Dashboard/SideBar";
import { SpeedPost } from "./Post";

const Posts: React.FC = () => {
  return (
    <Box d="flex" h="100vh" flexDirection={["column", "row"]} flex="1">
      <SideBar
        itens={[
          {
            name: "Dashboard",
            to: "/admin/dashboard",
            icon: HomeIcon,
            active: false,
          },
          {
            name: "Blog post",
            to: "/admin/posts",
            icon: BlogIcon,
            active: false,
          },
          {
            name: "Nova postagem",
            to: "/admin/new/post",
            icon: BlogIcon,
            active: true,
          },
          {
            name: "Log Out",
            to: "",
            icon: LogoutIcon,
            active: false,
            handleClick: () => {
              localStorage.removeItem("jwt");
              window.location.href = "/admin";
            },
          },
        ]}
      />
      <SpeedPost />
    </Box>
  );
};

export default Posts;
