import React, { useState } from "react";
import { Box, Stack, Text } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import RichTextEditor, { EditorValue } from "react-rte";
import { Button } from "@chakra-ui/button";
import { config } from "../Dashboard/ToolbarConfig";
import api from "../../components/api";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { iPosts } from "./index";

export const SpeedPost: React.FC = () => {
  const [file, setFile] = useState<any>();
  const [title, setTitle] = useState("");
  const [fileName, setFileName] = useState("Nenhum arquivo selecionado");
  const [posts, setPosts] = useState<iPosts[]>([]);
  const [data, setData] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  async function handleSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();

    const MySwal = withReactContent(Swal);

    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("titulo", title);
    formdata.append("code", data.toString("html"));
    formdata.append("type", "blog");

    if (!title) {
      await MySwal.fire({
        title: "Sua postagem precisa de um título.",
        icon: "error",
      });
      return;
    }
    if (!file) {
      await MySwal.fire({
        title: "Sua postagem precisa de uma imagem.",
        icon: "error",
      });
      return;
    }

    try {
      await api.post("/blog", formdata, {
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      });

      await MySwal.fire({
        title: "Postagem criada com sucesso.",
        icon: "success",
      });

      setPosts([
        {
          titulo: title,
          code: data.toString("html"),
          type: "blog",
          filename: "",
          id: posts.length + 1,
          created_at: new Date(),
          updated_at: new Date(),
        },
        ...posts,
      ]);
      setFile("");
      setTitle("");
      setData(data.setContentFromString("", "html"));
      setFileName("Nenhum arquivo selecionado");
    } catch (err) {}
  }

  return (
    <>
      <Box w="100%" h="max-content" d={["none", "flex"]}>
        <FormControl
          onSubmit={handleSubmit}
          as="form"
          w={["90%"]}
          h="100%"
          bg="#fff"
          borderRadius="8px"
          p={8}
          d="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Text
            fontWeight="bold"
            color="#333"
            fontSize="2xl"
            fontFamily="Poppins"
          >
            Criar nova postagem
          </Text>
          <Stack spacing={16} flexDirection="column" w="80%" h="100%" mt={8}>
            <Box>
              <Text fontSize="xl" fontFamily="Poppins">
                Título
              </Text>
              <Input
                focusBorderColor="green.400"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </Box>
            <Box mt={4} overflow="scroll" maxH={["20vh", "50vh"]}>
              <RichTextEditor
                value={data}
                onChange={setData}
                toolbarConfig={config}
              />
            </Box>
            <Box
              d="grid"
              gridTemplateColumns="repeat(2,1fr)"
              alignItems="center"
            >
              <Input
                hidden
                type="file"
                id="FilesImages"
                accept="image/*"
                onChange={(e) => {
                  // @ts-ignore
                  setFileName(e.target.files[0].name);
                  // @ts-ignore
                  setFile(e.target.files[0]);
                }}
              />
              <Button
                as={FormLabel}
                bg="#4ECB71"
                color="#fff"
                _hover={{}}
                _focus={{}}
                _active={{}}
                fontFamily="Poppins"
                htmlFor={"FilesImages"}
                fontWeight="semibold"
                shadow="0px 0px 10px rgba(78, 203, 113, 0.25)"
              >
                Selecionar imagem
              </Button>
              <Text fontFamily="Poppins" fontSize="18px" fontWeight="semibold">
                {fileName}
              </Text>
            </Box>
            <Box w="100%">
              <Button
                bg="rgba(78, 203, 113)"
                color="white"
                w="100%"
                _hover={{}}
                _focus={{}}
                _active={{}}
                shadow="0px 0px 10px rgba(78, 203, 113, 0.25)"
                type="submit"
              >
                Postar
              </Button>
            </Box>
          </Stack>
        </FormControl>
      </Box>
    </>
  );
};
