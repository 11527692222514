import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

interface iEmphasis {
  title: string;
  description: string;
  link: string;
  filename: string;
}

export const Item: React.FC<iEmphasis> = ({
  title,
  description,
  link,
  filename,
}) => {
  return (
    <>
      <Link to={link}>
        <Box
          d="flex"
          w="100%"
          flexDir={["column", "row"]}
          alignItems="center"
          mb={[2, 0]}
        >
          <Box
            minW="187.85px"
            maxW="187.85px"
            minH="184px"
            maxH="184px"
            bg="#C4C4C4"
            sx={{
              backgroundImage: `${process.env.REACT_APP_API_IMG}/${filename}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            shadow="10px 10px 40px rgba(7, 40, 43, 0.14);"
            rounded="14px"
          ></Box>
          <Box ml={[0, 3]} minW={["50%", "25%"]} maxW={["90%", "50%"]}>
            <Text
              fontSize="4xl"
              fontWeight="black"
              noOfLines={[1, 1]}
              title={title}
            >
              {title}
            </Text>

            <Text fontSize="18px" noOfLines={2} color="gray.500">
              {HTMLReactParser(description.split("</p>")[0])}
            </Text>
            <Text mt={2} color="green" fontWeight="bold" fontSize="18">
              Leia mais
            </Text>
          </Box>
        </Box>
      </Link>
    </>
  );
};
