import React, { useEffect, useState } from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import Header from "../components/Header";
import { ItemHeader } from "./ItemHeader";
import { iPosts } from "../Home/iPosts";
import api from "../components/api";
import { useParams } from "react-router";
import HTMLReactParser from "html-react-parser";

const Post: React.FC = () => {
  const [post, setPost] = useState<iPosts>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async () => {
      const response = await api.get<iPosts>(`/posts/${id}`);
      setPost(response.data);
    })();
  }, [id]);
  return (
    <>
      <Header />
      <Box as="main" d="flex" justifyContent="center">
        <Box d={["block"]} maxW={["", "1200px"]} w="100%" mt={16}>
          <ItemHeader
            title={post?.titulo || ""}
            filename={post?.filename || ""}
          />
          <Box
            d="flex"
            alignItems={["center", ""]}
            justifyContent={["center", "left"]}
            mt={8}
            mb={16}
          >
            <Stack spacing={5} fontSize="24px" maxW={["336px", "725px"]}>
              {HTMLReactParser(post?.code || "")}
              <Flex w="100%" flexDir="column">
                <Text>Autor Conexão NR</Text>
                <Text>
                  Postado em{" "}
                  {new Date(post?.created_at || "").toLocaleDateString()}
                </Text>
              </Flex>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Post;
