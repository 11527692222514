import { Button } from "@chakra-ui/button";
import { FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Flex, Stack, Text } from "@chakra-ui/layout";
import React, { FormEvent, useEffect, useState } from "react";
import RichTextEditor, { EditorValue } from "react-rte";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../../components/api";
import { SideBar } from "../SideBar";
import { config } from "./ToolbarConfig";

const Edit: React.FC = () => {
  const [title, setTitle] = useState("");
  const [data, setData] = useState<EditorValue>(
    RichTextEditor.createEmptyValue()
  );

  const MySwal = withReactContent(Swal);
  async function comeback() {
    MySwal.fire({
      icon: "warning",
      title: "Tem certeza que deseja cancelar?",
      text: "Os dados informados serão perdidos.",
      showConfirmButton: true,
      showCancelButton: true,
    }).then((response) => {
      if (response.isConfirmed) {
        window.location.href = "/admin/dashboard";
      }
    });
  }
  // const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(
          `/posts/${localStorage.getItem("postID")}`
        );
        setTitle(response.data.titulo);
        setData(data.setContentFromString(response.data.code, "html"));
      } catch (err) {
        console.log(err);
      }
    })();
    console.log(localStorage.getItem("postID"));
    // eslint-disable-next-line
  }, []);

  async function handleSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();
    try {
      const response = await api.put(
        "/blog",
        {
          titulo: title,
          code: data.toString("html"),
          type: "blog",
        },
        {
          headers: {
            id: localStorage.getItem("postID"),
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (response.status === 200) {
        MySwal.fire({
          icon: "success",
          title: "Registro salvo com sucesso",
        }).then((response) => {
          if (response.isConfirmed) {
            window.location.href = "/admin/dashboard";
          }
        });
      }
    } catch (err) {}
  }

  return (
    <>
      <Flex flexDirection={["column", "row"]} flex="1">
        <SideBar />
        <Box
          d="flex"
          justifyContent="space-between"
          flexDirection="column"
          overflowX="hidden"
          w="100%"
          minH="100vh"
          h="max-content"
          bg="#F4F6FA"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            h="100vh"
            mt={[0, 8]}
            ml={[0, 5]}
            py={[8, 0]}
            px={[4, 0]}
          >
            <Flex
              as="form"
              onSubmit={handleSubmit}
              // justifyContent="center"
              alignItems="center"
              maxW="35%"
              w="100%"
              bg="#fff"
              shadow="0px 0px 10px rgba(0, 0, 0, 0.05)"
              py={8}
              px={6}
              rounded="8px"
              flexDirection="column"
            >
              <Text fontWeight="bold" fontSize="3xl">
                Editar Postagem
              </Text>
              <Box w="100%">
                <FormLabel htmlFor="title" sx={{ fontFamily: "Poppins" }}>
                  Título
                </FormLabel>
                <Input
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Box>
              <Box w="100%" mt={4}>
                <RichTextEditor
                  value={data}
                  onChange={setData}
                  toolbarConfig={config}
                />
              </Box>
              <Stack mt={3} direction="row" as={Flex} w="100%">
                <Button w="100%" onClick={comeback}>
                  Cancelar
                </Button>
                <Button w="100%" colorScheme="green" type="submit">
                  Salvar
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Edit;
